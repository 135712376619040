import { buttonVariants } from "package:/components/elements/Button";
import { Icon } from "package:/components/elements/Icon";
import { useTranslations } from "#imports";
import { Link } from "../elements/Link";

const variants = {
  dark: "bg-green-500",
  darker: "bg-green-800",
};

export function SocialLinks(props: {
  variant: "dark" | "darker";
}) {
  const t = useTranslations();

  return (
    <>
      {t("social.instagram.url") && (
        <Link
          class={[
            buttonVariants.iconDark,
            "pointer-events-auto text-xl",
            variants[props.variant],
          ]}
          href={t("social.instagram.url")}
          label={t("social.instagram.ariaLabel")}
        >
          <Icon name="instagram" aria-hidden="true" />
        </Link>
      )}
      {t("social.linkedin.url") && (
        <Link
          class={[
            buttonVariants.iconDark,
            "pointer-events-auto text-xl",
            variants[props.variant],
          ]}
          href={t("social.linkedin.url")}
          label={t("social.linkedin.ariaLabel")}
        >
          <Icon name="linkedin" aria-hidden="true" />
        </Link>
      )}
      {t("social.youtube.url") && (
        <Link
          class={[
            buttonVariants.iconDark,
            "pointer-events-auto text-xl",
            variants[props.variant],
          ]}
          href={t("social.youtube.url")}
          label={t("social.youtube.ariaLabel")}
        >
          <Icon name="youtube" aria-hidden="true" />
        </Link>
      )}
      {t("social.facebook.url") && (
        <Link
          class={[
            buttonVariants.iconDark,
            "pointer-events-auto text-xl",
            variants[props.variant],
          ]}
          href={t("social.facebook.url")}
          label={t("social.facebook.ariaLabel")}
        >
          <Icon name="facebook" aria-hidden="true" />
        </Link>
      )}
    </>
  );
}
